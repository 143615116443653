import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

export default function FormImage({ id }) {
  const [image, setImage] = useState();

  const selectImage = () => {
    document.getElementById("image"+id).click();
  };

  const chooseImage = () => {
    console.log("image"+id);
    const file = document.getElementById("image"+id).files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
  };

  return (
    <div
      onClick={selectImage}
      className="border border-gray-500 cursor-pointer rounded-lg w-[240px] h-[180px] grid justify-center items-center hover:bg-gray-100"
    >
      {image ? (
        <img src={image} className="p-2 object-cover" />
      ) : (
        <AiOutlinePlus className="text-gray-300 text-6xl" />
      )}

      <input
        type="file"
        id={"image"+id}
        name={"image"+id}
        accept="image/*"
        className="hidden"
        onChange={chooseImage}
      />
    </div>
  );
}
