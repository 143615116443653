import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {getAuth} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAF3-7slMi3aBKAWvU3VwhBWtYKxkQRcs8",
  authDomain: "topgunauto-d7d3d.firebaseapp.com",
  projectId: "topgunauto-d7d3d",
  storageBucket: "topgunauto-d7d3d.appspot.com",
  messagingSenderId: "871216473445",
  appId: "1:871216473445:web:404b0dd4da63d45862ed8c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);