import { Button } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Vehicles() {
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchVehicles = async () => {
    const snapshot = await getDocs(collection(db, "Vehicles"));
    const data = snapshot.docs.map((doc) => doc.data());
    setVehicles(data);
  };

  useEffect(() => {
    fetchVehicles();
    setLoading(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="grid content-start min-h-screen gap-4 bg">
      <div className="grid gap-12 page">
        <div className="flex items-center justify-between w-full">
          <p className="h1">Vehicles</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/add-vehicle")}
          >
            Add New Vehicle
          </Button>
        </div>
        {loading ? (
          <div className="loading-conatiner">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="grid gap-2">
            <div className="hidden grid-cols-7 p-4 text-sm font-bold md:grid">
              <p className="text-left">VIN</p>
              <p className="text-center">Make</p>
              <p className="text-left">Model/Trim</p>
              <p className="text-center">Year</p>
              <p className="text-center">Mileage</p>
              <p className="text-center">Price</p>
              <p className="text-center">Status</p>
            </div>
            <div className="grid max-h-screen gap-2 overflow-scroll no-scrollbar">
              {vehicles.map((vehicle) => (
                <div
                  key={vehicle.vin}
                  onClick={() => navigate(`/edit-vehicle?id=${vehicle.vin}`)}
                  className="grid grid-cols-6 md:block pill gap-x-2"
                >
                  <div className="grid col-span-2 text-sm font-bold md:hidden">
                    <p className="text-left">VIN</p>
                    <p className="text-left">Make</p>
                    <p className="text-left">Model/Trim</p>
                    <p className="text-left">Year</p>
                    <p className="text-left">Mileage</p>
                    <p className="text-left">Price</p>
                    <p className="text-left">Status</p>
                  </div>
                  <div className="col-span-4 md:grid md:grid-cols-7">
                    <p className="md:text-left">{vehicle.vin}</p>
                    <p className="md:text-center">{vehicle.make}</p>
                    <p className="md:text-left">
                      {vehicle.model} {vehicle.trim}
                    </p>
                    <p className="md:text-center">{vehicle.year}</p>
                    <p className="md:text-center">{vehicle.mileage}mi</p>
                    <p className="md:text-center">${vehicle.price}</p>
                    <p className="md:text-center">{vehicle.status}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
