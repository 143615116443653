import FormInput from "../components/FormInput";
import FormCheck from "../components/FormCheck";
import FormSelect from "../components/FormSelect";
import FormImage from "../components/FormImage";
import { Fragment, useEffect, useState } from "react";
import { Button } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";
import { useSearchParams } from "react-router-dom";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { AiFillDelete } from "react-icons/ai";
import { uploadToFirebase } from "../util/uploadToFirebase";
import { useNavigate } from "react-router-dom";

export default function EditVehicle() {
  const [vehicle, setVehicle] = useState();
  const [numImages, setNumImages] = useState(2);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();
  const [progress, setProgress] = useState();

  const [searchParams] = useSearchParams();
  const vehicleId = searchParams.get("id");

  const navigate = useNavigate();

  const fetchVehicle = async () => {
    try {
      setLoading(true);
      const document = await getDoc(doc(db, "Vehicles", vehicleId));
      setVehicle(document.data());
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning("Error fetching vehicle");
      setLoading(false);
    }
  };

  const deleteVehicleHandler = async () => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "Vehicles", vehicleId));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning("Error deleting vehicle");
      setLoading(false);
    }
  };

  const updateVehicle = async (event) => {
    event.preventDefault();
    const data = {
      make: event.target.make?.value,
      model: event.target.model?.value,
      year: event.target.year?.value,
      trim: event.target.trim?.value,
      color: event.target.color?.value,
      vin: event.target.vin?.value,
      mileage: event.target.mileage?.value,
      price: event.target.price?.value,
      drive: event.target.drive?.value,
      fuel: event.target.fuel?.value,
      body: event.target.body?.value,
      doors: event.target.doors?.value,
      seats: event.target.seats?.value,
      title: event.target.title?.value,
      status: event.target.status?.value,
      features: {
        heatedseats: event.target.heatedseats.checked,
        rearcamera: event.target.rearcamera.checked,
        headsupdisplay: event.target.headsupdisplay.checked,
        blindspotassist: event.target.blindspotassist.checked,
        ambientlighting: event.target.ambientlighting.checked,
        androidauto: event.target.androidauto.checked,
        applecarplay: event.target.applecarplay.checked,
        navigation: event.target.navigation.checked,
        remotestart: event.target.remotestart.checked,
        sunroof: event.target.sunroof.checked,
        bluetooth: event.target.bluetooth.checked,
        wirelesscharging: event.target.wirelesscharging.checked,
      },
    };
    try {
      const images = [];
      for (let i = 0; i < numImages; i++) {
        if (event.target["image" + i].files[0])
          images.push(event.target["image" + i].files[0]);
      }
      const imageURLs = [];
      await Promise.all(
        images.map(async (img) => {
          const count = images.indexOf(img) + 1;
          const path = `vehicles/${data.vin}/image${count}`;
          const url = await uploadToFirebase(path, img, setProgress);
          imageURLs.push(url);
        })
      );
      imageURLs.push(...vehicle.images);
      data.images = imageURLs;
      await updateDoc(doc(db, "Vehicles", vehicleId), data);
      setLoading(false);
      navigate("/vehicles");
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning("Error updating vehicle. Please try again.");
    }
  };

  const deleteImageHandler = (idx) => {
    const udpatedVehicle = { ...vehicle };
    udpatedVehicle.images.splice(idx, 1);
    setVehicle(udpatedVehicle);
  };

  useEffect(() => {
    fetchVehicle();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading)
    return (
      <div className="loading-container min-h-screen">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="bg">
      <div className="page grid gap-8">
        <div className="flex justify-between">
          <p className="h1">Edit Vehicle</p>
          <Button
            onClick={() => navigate("/vehicles")}
            variant="contained"
            color="primary"
          >
            Return
          </Button>
        </div>
        <form onSubmit={updateVehicle} className="grid gap-8">
          <div className="grid md:grid-cols-4 gap-4">
            <FormInput label="Make" defaultValue={vehicle.make} />
            <FormInput label="Model" defaultValue={vehicle.model} />
            <FormInput label="Year" type="number" defaultValue={vehicle.year} />
            <FormInput label="Trim" defaultValue={vehicle.trim} />
            <FormInput label="Color" defaultValue={vehicle.color} />
            <FormInput label="VIN" defaultValue={vehicle.vin} disabled={true} />
            <FormInput
              label="Mileage"
              type="number"
              defaultValue={vehicle.mileage}
            />
            <FormInput
              label="Price"
              type="number"
              defaultValue={vehicle.price}
            />
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 gap-4">
            <FormSelect
              label="Drive"
              options={["AWD", "RWD", "FWD"]}
              defaultvalue={vehicle.drive}
            />
            <FormSelect
              label="Fuel"
              options={["Gas", "Hybrid", "Plugin Hybrid", "Electric"]}
              defaultValue={vehicle.fuel}
            />
            <FormSelect
              label="Body"
              options={[
                "SUV",
                "Sedan",
                "Coupe",
                "Hatchback",
                "Truck",
                "Van",
                "Crossover",
              ]}
              defaultValue={vehicle.body}
            />
            <FormSelect
              label="Doors"
              options={[2, 4, 6]}
              defaultValue={vehicle.doors}
            />
            <FormSelect
              label="Seats"
              options={[2, 4, 5, 7]}
              defaultValue={vehicle.seats}
            />
            <FormSelect
              label="Title"
              options={["Clean Title", "Salvaged Title", "Rebuilt Title"]}
              defaultValue={vehicle.title}
            />
            <FormSelect
              label="Status"
              options={["Available", "Sold", "Unlisted"]}
              defaultValue={vehicle.status}
            />
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 gap-4">
            <FormCheck
              label="Heated Seats"
              checked={vehicle.features.heatedseats}
            />
            <FormCheck
              label="Rear Camera"
              checked={vehicle.features.rearcamera}
            />
            <FormCheck
              label="Heads Up Display"
              checked={vehicle.features.headsupdisplay}
            />
            <FormCheck label="Blind Spot Assist" checked={true} />
            <FormCheck
              label="Ambient Lighting"
              checked={vehicle.features.ambientlighting}
            />
            <FormCheck
              label="Android Auto"
              checked={vehicle.features.androidauto}
            />
            <FormCheck
              label="Apple Carplay"
              checked={vehicle.features.applecarplay}
            />
            <FormCheck
              label="Navigation"
              checked={vehicle.features.navigation}
            />
            <FormCheck
              label="Remote Start"
              checked={vehicle.features.remotestart}
            />
            <FormCheck label="Sunroof" checked={vehicle.features.sunroof} />
            <FormCheck label="Bluetooth" checked={vehicle.features.bluetooth} />
            <FormCheck
              label="Wireless Charging"
              checked={vehicle.features.wirelesscharging}
            />
          </div>
          <hr className="border" />
          <p className="text-primary font-semibold text-xl">Current Images:</p>

          <div className="grid md:grid-cols-4 justify-items-center gap-8">
            {vehicle.images.map((img, idx) => (
              <div
                key={idx}
                className="relative border border-gray-500 cursor-pointer rounded-lg w-[240px] h-[180px]"
              >
                <img src={img} className="p-2 object-cover" />
                <AiFillDelete
                  onClick={() => deleteImageHandler(idx)}
                  className="text-2xl absolute text-red-600 top-0 hover:opacity-75"
                />
              </div>
            ))}
          </div>
          <hr className="border" />
          <div className="flex justify-between">
            <p className="text-primary font-semibold text-xl">New Images:</p>
            <div className="flex space-x-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNumImages(numImages + 1)}
              >
                Add Image
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNumImages(numImages - 1)}
              >
                Remove Image
              </Button>
            </div>
          </div>
          <div className="grid md:grid-cols-4 justify-items-center gap-8">
            {[...Array(numImages).keys()].map((num) => (
              <FormImage key={num} id={num.toString()} />
            ))}
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 items-center gap-4">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Fragment>
                <button
                  type="submit"
                  className="bg-primary hover:opacity-75 text-white rounded-md px-3 py-2"
                >
                  Update Vehicle
                </button>
                <button
                  type="submit"
                  className="bg-red-700 hover:opacity-75 text-white rounded-md px-3 py-2"
                  onClick={deleteVehicleHandler}
                >
                  Delete Vehicle
                </button>
              </Fragment>
            )}
            {progress && (
              <p className="text-gray-600 text-sm">
                Upload Progress: {progress}%
              </p>
            )}
            {warning && <p className="text-sm text-red-600">{warning}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}
