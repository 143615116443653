export default function FormCheck({ label, checked }) {
  if (!label) return <></>;
  return (
    <div className="grid grid-cols-3">
      <p className="label col-span-2">{label}:</p>
      <input
        className="checkbox"
        type="checkbox"
        name={label.replaceAll(" ", "").toLowerCase()}
        defaultChecked={checked || false}
      />
    </div>
  );
}
