import { useEffect, useState } from "react";
import FormCheck from "../components/FormCheck";
import FormImage from "../components/FormImage";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import { Button } from "@mui/material";
import LoadingSpinner from "../components/LoadingSpinner";
import { uploadToFirebase } from "../util/uploadToFirebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function AddVehicle() {
  const [numImages, setNumImages] = useState(4);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [progress, setProgress] = useState();

  const navigate = useNavigate();

  const submitHandler = async (event) => {
    event.preventDefault();
    const data = {
      make: event.target.make.value,
      model: event.target.model.value,
      year: event.target.year.value,
      trim: event.target.trim.value,
      color: event.target.color.value,
      vin: event.target.vin.value,
      mileage: event.target.mileage.value,
      price: event.target.price.value,
      drive: event.target.drive.value,
      fuel: event.target.fuel.value,
      body: event.target.body.value,
      doors: event.target.doors.value,
      seats: event.target.seats.value,
      title: event.target.title.value,
      status: event.target.status.value,
      features: {
        heatedseats: event.target.heatedseats.checked,
        rearcamera: event.target.rearcamera.checked,
        headsupdisplay: event.target.headsupdisplay.checked,
        blindspotassist: event.target.blindspotassist.checked,
        ambientlighting: event.target.ambientlighting.checked,
        androidauto: event.target.androidauto.checked,
        applecarplay: event.target.applecarplay.checked,
        navigation: event.target.navigation.checked,
        remotestart: event.target.remotestart.checked,
        sunroof: event.target.sunroof.checked,
        bluetooth: event.target.bluetooth.checked,
        wirelesscharging: event.target.wirelesscharging.checked,
      },
    };
    try {
      const images = [];
      for (let i = 0; i < numImages; i++) {
        if (event.target["image" + i].files[0])
          images.push(event.target["image" + i].files[0]);
      }
      const imageURLs = [];
      await Promise.all(images.map(async (img) => {
        const count = images.indexOf(img) + 1;
        const path = `vehicles/${data.vin}/image${count}`;
        const url = await uploadToFirebase(path, img, setProgress);
        imageURLs.push(url);
      }))
      data.images = imageURLs;
      await setDoc(doc(db, "Vehicles", data.vin), data);
      setLoading(false);
      navigate("/vehicles");
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning("Error adding vehicle. Please try again.");
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg">
      <div className="page grid gap-8">
      <div className="flex justify-between">
          <p className="h1">Add New Vehicle</p>
          <Button
            onClick={() => navigate("/vehicles")}
            variant="contained"
            color="primary"
          >
            Return
          </Button>
        </div>
        <form onSubmit={submitHandler} className="grid gap-8">
          <div className="grid md:grid-cols-4 gap-4">
            <FormInput label="Make" />
            <FormInput label="Model" />
            <FormInput label="Year" type="number" />
            <FormInput label="Trim" />
            <FormInput label="Color" />
            <FormInput label="VIN" />
            <FormInput label="Mileage" type="number" />
            <FormInput label="Price" type="number" />
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 gap-4">
            <FormSelect label="Drive" options={["AWD", "RWD", "FWD"]} />
            <FormSelect
              label="Fuel"
              options={["Gas", "Hybrid", "Plugin Hybrid", "Electric"]}
            />
            <FormSelect
              label="Body"
              options={[
                "SUV",
                "Sedan",
                "Coupe",
                "Hatchback",
                "Truck",
                "Van",
                "Crossover",
              ]}
            />
            <FormSelect label="Doors" options={[2, 4, 6]} />
            <FormSelect label="Seats" options={[2, 4, 5, 7]} />
            <FormSelect
              label="Title"
              options={["Clean Title", "Salvaged Title", "Rebuilt Title"]}
            />
            <FormSelect
              label="Status"
              options={["Available", "Sold", "Unlisted"]}
            />
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 gap-4">
            <FormCheck label="Heated Seats" />
            <FormCheck label="Rear Camera" checked={true} />
            <FormCheck label="Heads Up Display" />
            <FormCheck label="Blind Spot Assist" checked={true} />
            <FormCheck label="Ambient Lighting" />
            <FormCheck label="Android Auto" checked={true} />
            <FormCheck label="Apple Carplay" checked={true} />
            <FormCheck label="Navigation" />
            <FormCheck label="Remote Start" />
            <FormCheck label="Sunroof" />
            <FormCheck label="Bluetooth" checked={true} />
            <FormCheck label="Wireless Charging" />
          </div>
          <hr className="border" />
          <div className="flex justify-between">
            <p className="text-primary font-semibold text-xl">Images:</p>
            <div className="flex space-x-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNumImages(numImages + 1)}
              >
                Add Image
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setNumImages(numImages - 1)}
              >
                Remove Image
              </Button>
            </div>
          </div>
          <div className="grid md:grid-cols-4 justify-items-center gap-8">
            {[...Array(numImages).keys()].map((num) => (
              <FormImage key={num} id={num.toString()} />
            ))}
          </div>
          <hr className="border" />
          <div className="grid md:grid-cols-4 items-center gap-4">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <button
                type="submit"
                className="bg-primary hover:opacity-75 text-white rounded-md px-3 py-2"
              >
                Add Vehicle
              </button>
            )}
            {progress && (
              <p className="text-gray-600 text-sm">
                Upload Progress: {progress}%
              </p>
            )}
            {warning && <p className="text-sm text-red-600">{warning}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}
