import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Vehicles from "./pages/Vehicles";
import AddVehicle from "./pages/AddVehicle";
import NotFound from "./pages/404";
import Login from "./pages/Login";
import { useContext, Fragment } from "react";
import { AuthContext } from "./context/auth-context";
import EditVehicle from "./pages/EditVehicle";
import Inbox from "./pages/Inbox";
import Settings from "./pages/Settings";
import Ledgers from "./pages/Ledgers";
import Ledger from "./pages/Ledger";

const theme = createTheme({
  palette: {
    primary: {
      main: "#22668D",
    },
    secondary: {
      main: "#8ECDDD",
    },
    tertiary: {
      main: "#FFCC70",
    },
    warning: {
      main: "#b91c1c",
    },
  },
});

function App() {
  const { user } = useContext(AuthContext);
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Routes>
        {user ? (
          <Fragment>
            <Route path="/" element={<Vehicles />} />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/add-vehicle" element={<AddVehicle />} />
            <Route path="/edit-vehicle" element={<EditVehicle />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/ledgers" element={<Ledgers />} />
            <Route path="/ledger" element={<Ledger />} />
            <Route path="/settings" element={<Settings />} />
          </Fragment>
        ) : (
          <Route path="/*" element={<Login />} />
        )}
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
