export default function FormSelect({ label, options, values, defaultValue, required }) {
  if (!label || !options) return <></>;
  return (
    <div>
      <p className="label">{label.toUpperCase()}:</p>
      <select className="w-full select" name={label.replaceAll(" ", "").toLowerCase()} defaultValue={defaultValue} required={required}>
        {options.map((option, idx) => (
          <option key={idx} value={values ? values[idx] : option}>{option}</option>
        ))}
      </select>
    </div>
  );
}
