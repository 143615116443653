import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import FormInput from "../components/FormInput";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Settings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();

  const fetchSettings = async () => {
    setLoading(true);
    const response = await getDoc(doc(db, "Settings", "settings"));
    setSettings(response.data());
    setLoading(false);
  };

  const updateSettings = async (event) => {
    event.preventDefault();
    const data = {
      businessAddress: event.target.businessaddress.value,
      businessPhone1: event.target.businessphone1.value,
      businessPhone2: event.target.businessphone2.value,
      businessEmail: event.target.businessemail.value,
      facebookURL: event.target.facebookurl.value,
      instagramURL: event.target.instagramurl.value,
      youtubeURL: event.target.youtubeurl.value,
      tiktokURL: event.target.tiktokurl.value,
    };

    try {
      setWarning();
      setLoading(true);
      await setDoc(doc(db, "Settings", "settings"), data);
      setSettings(data);
      setLoading(false);
    } catch (err) {
      setWarning("Something went wrong. Please try again later.");
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSettings();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading) return <div className="loading-container h-[600px]"><LoadingSpinner /></div>

  return (
    <div className="bg">
      <div className="page grid gap-4">
        <div className="flex justify-between">
          <p className="h1">Settings</p>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            color="primary"
          >
            RETURN
          </Button>
        </div>

        <form onSubmit={updateSettings} className="grid grid-cols-2">
          <div className="grid gap-2">
            <FormInput label="Business Address" defaultValue={settings?.businessAddress} />
            <FormInput label="Business Phone 1" defaultValue={settings?.businessPhone1} />
            <FormInput label="Business Phone 2" defaultValue={settings?.businessPhone2} />
            <FormInput label="Business Email" defaultValue={settings?.businessEmail} />
            <hr className="border my-4" />
            <FormInput label="Facebook URL" defaultValue={settings?.facebookURL} />
            <FormInput label="Instagram URL" defaultValue={settings?.instagramURL} />
            <FormInput label="Youtube URL" defaultValue={settings?.youtubeURL} />
            <FormInput label="Tiktok URL" defaultValue={settings?.tiktokURL} />
            <button className="btn-primary mt-4">Save Settings</button>
            {warning && <p className="text-red-500 text-sm">{warning}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}
