import { Button } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Inbox() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const fetchMessages = async () => {
    setLoading(true);
    const data = await getDocs(collection(db, "Messages"));
    setMessages(data.docs.map((doc) => doc.data()));
    setLoading(false);
  };

  useEffect(() => {
    fetchMessages();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading) return <div className="loading-container h-[600px]"><LoadingSpinner /></div>

  return (
    <div className="bg min-h-[600px]">
      <div className="page grid gap-8 content-start">
        <div className="flex justify-between">
          <p className="h1">Inbox</p>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            color="primary"
          >
            RETURN
          </Button>
        </div>
        <div className="grid gap-2">
          <div className="grid grid-cols-5 text-sm px-4 font-semibold">
            <p>Date</p>
            <p>Name</p>
            <p>Phone</p>
            <p>Vehicle</p>
            <p>Message</p>
          </div>
          {messages.map((message) => (
            <div className="border p-4 rounded-md grid grid-cols-5 text-sm text-gray-600">
              <p>{message.date.toDate().toISOString()}</p>
              <p>{message.name}</p>
              <p>{message.phone}</p>
              <p>{message.vehicle}</p>
              <p>{message.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
