import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/auth-context";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { format } from "date-fns";

export default function Ledgers() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();
  const [ledgers, setLedgers] = useState([]);
  const [tab, setTab] = useState("expense");

  const addHandler = () => {
    if (tab === "vehicles") navigate("/vehicles/add");
    else navigate(`/ledger?type=${tab}`);
  };

  const fetchLedgers = async () => {
    try {
      setLoading(true);
      const response = await getDocs(collection(db, "Ledgers"));
      const data = response.docs
        .map((doc) => doc.data())
        .filter((ledger) => ledger.type === tab);
      data.sort((a, b) => b.date.toDate() - a.date.toDate());
      setLedgers(data);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setWarning(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLedgers();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [tab]);

  if (user.email !== "huzefa@topgunautomobiles.com")
    return (
      <div className="main">
        <div className="grid gap-4 mx-auto page md:w-1/2">
          <p className="h1">Access Denied</p>
          <p className="mb-4">You do not have access to this feature!</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Return
          </Button>
        </div>
      </div>
    );

  return (
    <div className="main">
      <div className="min-h-screen page">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(e, v) => setTab(v)}
            aria-label="basic tabs example"
          >
            <Tab label="Expenses" value="expense" />
            <Tab label="Income" value="income" />
          </Tabs>
        </Box>

        <div className="hidden grid-cols-5 p-4 text-sm font-bold md:grid">
          <p className="text-left">Date</p>
          <p className="text-left">Description</p>
          <p className="text-left">Vehicle</p>
          <p className="text-left">Notes</p>
          <p className="text-center">Amount</p>
        </div>

        <div className="grid max-h-screen gap-2 mt-12 mb-12 overflow-scroll md:mt-0 no-scrollbar">
          {ledgers.map((ledger, idx) => (
            <div
              className="grid grid-cols-6 md:block pill gap-x-2"
              key={idx}
              onClick={() => navigate(`/ledger?id=${ledger.id}`)}
            >
              <div className="grid col-span-2 text-sm font-bold md:hidden">
                <p className="text-left">Date</p>
                <p className="text-left">Description</p>
                <p className="text-left">Vehicle</p>
                <p className="text-left">Notes</p>
                <p className="text-left">Amount</p>
              </div>
              <div className="col-span-4 md:grid md:grid-cols-5">
                <p className="text-left">
                  {format(ledger.date.toDate(), "MM/dd/yyyy")}
                </p>
                <p className="text-left">{ledger.description}</p>
                <p className="text-left">{ledger.vehicle}</p>
                <p className="text-left">{ledger.notes}</p>
                <p className="text-left md:text-center">${ledger.amount}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between">
          <Button variant="contained" color="primary" onClick={addHandler}>
            {`Add ${tab}`}
          </Button>
          <p className="text-sm text-stone-400">
            Items: {ledgers.length} - Total Amount: $
            {Number(
              ledgers.reduce((a, b) => Number(a) + Number(b.amount || 0), 0)
            ).toFixed(2)}
          </p>
        </div>
        {warning && <p className="text-red-500">{warning}</p>}
      </div>
    </div>
  );
}
