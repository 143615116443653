import { useContext, useState } from "react";
import FormInput from "../components/FormInput";
import { AuthContext } from "../context/auth-context";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const { login, loadingUser, warning } = useContext(AuthContext);

  const loginHandler = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    login(email, password);
  };

  return (
    <div className="bg grid justify-items-center items-center min-h-[600px]">
      <div className="grid w-full gap-8 page md:w-1/2">
        <p className="h1">ADMIN LOGIN</p>
        {loadingUser ? (
          <div className="loading-container h-[224px]">
            <LoadingSpinner />
          </div>
        ) : (
          <form onSubmit={loginHandler} className="grid gap-3">
            <FormInput label="Email" required={true} />
            <FormInput
              label="Password"
              type={showPassword ? "text" : "password"}
              required={true}
            />
            <p
              onClick={() => setShowPassword(!showPassword)}
              className="text-sm text-gray-600 underline cursor-pointer"
            >
              {showPassword ? "Hide Password" : "Show Password"}
            </p>
            <button type="submit" className="btn-primary">
              Login
            </button>
            {warning && <p className="text-sm text-red-600">{warning}</p>}
          </form>
        )}
      </div>
    </div>
  );
}
