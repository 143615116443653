import { Fragment, useContext, useState } from "react";
import TGLogo from "../assets/img/tg-logo.png";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

export default function Navbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { user, logout } = useContext(AuthContext);

  const navigateTo = (path) => {
    setShow(false);
    navigate(path);
  };

  const menu = (
    <Fragment>
      <p
        onClick={() => navigateTo("/vehicles")}
        className="text-sm text-white cursor-pointer hover:text-secondary"
      >
        VEHICLES
      </p>
      <p
        onClick={() => navigateTo("/inbox")}
        className="text-sm text-white cursor-pointer hover:text-secondary"
      >
        INBOX
      </p>
      <p
        onClick={() => navigateTo("/ledgers")}
        className="text-sm text-white cursor-pointer hover:text-secondary"
      >
        LEDGERS
      </p>
      <p
        onClick={() => navigateTo("/settings")}
        className="text-sm text-white cursor-pointer hover:text-secondary"
      >
        SETTINGS
      </p>
    </Fragment>
  );

  return (
    <div>
      <div className="w-full p-6 bg-primary">
        <div className="items-center justify-between hidden space-x-6 md:flex">
          <div className="flex items-center space-x-6">
            <img
              src={TGLogo}
              onClick={() => navigateTo("/")}
              className="w-40 cursor-pointer"
              alt="TG Logo"
            />
            {menu}
          </div>
          {user && <div>
            <p onClick={logout} className="text-white cursor-pointer hover:underline">Log Out</p>
          </div>}
        </div>
        <div className="flex items-center justify-between md:hidden">
          <img
            src={TGLogo}
            onClick={() => navigateTo("/")}
            className="w-40 cursor-pointer"
            alt="TG Logo"
          />
          {show ? (
            <AiOutlineClose
              onClick={() => setShow(false)}
              className="w-6 h-6 text-white cursor-pointer"
            />
          ) : (
            <FiMenu
              onClick={() => setShow(true)}
              className="w-6 h-6 text-white cursor-pointer"
            />
          )}
        </div>
        {show && <div className="grid gap-4 mt-6 ml-4 md:hidden">{menu}</div>}
      </div>
    </div>
  );
}
