import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { createContext, useState } from "react";

export const AuthContext = createContext({
  user: null,
  loadingUser: true,
  setLoadingUser: () => {},
  login: () => {},
  logout: () => {},
  warning: false,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [warning, setWarning] = useState(false);

  // Firebase login
  const login = async (email, password) => {
    try {
      setLoadingUser(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUser(user);
      setLoadingUser(false);
    } catch (err) {
      setWarning(err.message);
      setLoadingUser(false);
    }
  };

  const logout = () => {
    setLoadingUser(true);
    auth.signOut();
    setUser(null);
    setLoadingUser(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loadingUser,
        setLoadingUser,
        warning,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
