export default function FormInput({ label, type, required, defaultValue, disabled, placeholder }) {
  if (!label) return <></>;
  return (
    <div className="grid w-full">
      <p className="label">{label.toUpperCase()}:</p>
      <input
        className="input"
        type={type || "text"}
        name={label.replaceAll(" ", "").toLowerCase()}
        required={required || false}
        defaultValue={defaultValue}
        placeholder={placeholder || ""}
        disabled={disabled}
      />
    </div>
  );
}
