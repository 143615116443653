export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const checkDateFormat = (date) => {
  // Format must match mm/dd/yyyy
  const re = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!re.test(date))
    throw new Error("Invalid date format. Must be mm/dd/yyyy");
  // Check month
  const month = parseInt(date.split("/")[0]);
  if (month < 1 || month > 12) throw new Error("Invalid month");
  // Check day
  const day = parseInt(date.split("/")[1]);
  if (day < 1 || day > 31) throw new Error("Invalid day");
  return;
};
