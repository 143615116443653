import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkDateFormat, toTitleCase } from "../util/tools";
import FormInput from "../components/FormInput";
import { Button } from "@mui/material";
import { format } from "date-fns";
import FormSelect from "../components/FormSelect";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { FaTrash } from "react-icons/fa";
import LoadingSpinner from "../components/LoadingSpinner";
import { uploadToFirebase } from "../util/uploadToFirebase";

export default function Ledger() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [warning, setWarning] = useState();

  const [vehicles, setVehicles] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const [ledger, setLedger] = useState({});

  // ============================ FETCH VEHICLES
  const fetchVehicles = async () => {
    try {
      const snapshot = await getDocs(collection(db, "Vehicles"));
      const data = snapshot.docs.map((doc) => doc.data());
      setVehicles(data);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
    }
  };

  // ============================ FETCH LEDGER
  const fetchLedger = async () => {
    try {
      setLoading(true);
      const ledger = await getDoc(doc(db, "Ledgers", id));
      setLedger(ledger.data());
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ============================ ADD LEDGER
  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setWarning();
      const vehicle = vehicles.find(
        (v) => v.vin === event.target.vehicle.value
      );
      let vehicleName = "general";
      if (vehicle)
        vehicleName = `${vehicle.make} ${vehicle.model} ${vehicle.year} ${vehicle.color}`;
      checkDateFormat(event.target.date.value);
      const data = {
        type: type || ledger.type,
        date: new Date(event.target.date.value),
        description: event.target.description.value,
        vin: event.target.vehicle.value,
        vehicle: vehicleName,
        notes: event.target.notes.value || "",
        amount: event.target.amount.value,
      };

      let response = {};
      if (id) {
        await updateDoc(doc(db, "Ledgers", id), data);
        response.id = id;
      } else {
        response = await addDoc(collection(db, "Ledgers"), data);
        await updateDoc(doc(db, "Ledgers", response.id), {
          id: response.id,
        });
      }

      if (numFiles > 0) {
        const urls = [];
        await Promise.all(
          Array(numFiles)
            .fill(null)
            .map(async (a, idx) => {
              if (event.target[`attachment${idx + 1}`].files[0]) {
                const file = event.target[`attachment${idx + 1}`].files[0];
                const path = `ledgers/${response.id}/attachment-${
                  idx + 1 + ledger.attachments?.length || 0
                }.${file.name.split(".").pop()}`;
                console.log(path);
                const url = await uploadToFirebase(path, file, setUploading);
                urls.push(url);
              }
            })
        );
        await updateDoc(doc(db, "Ledgers", response.id), {
          attachments: arrayUnion(...urls),
        });
      }
      navigate("/ledgers");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ============================ DELETE LEDGER
  const deleteLedger = async () => {
    try {
      setLoading(true);
      await setDoc(doc(db, "Deleted", id), ledger);
      await deleteDoc(doc(db, "Ledgers", id));
      navigate("/ledgers");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicles();
    if (id) fetchLedger();
    else setLoading(false);
  }, []);

  if (loading)
    return (
      <div className="loading-container h-[600px]">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="main">
      <div className="grid gap-8 page">
        <div className="flex items-center justify-between w-full">
          <p className="h1">
            {!id && "Add"} {toTitleCase(type || ledger.type)}{" "}
            {id && `ID: ${id}`}
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/ledgers")}
          >
            Return
          </Button>
        </div>

        <form className="grid gap-2 md:w-1/2" onSubmit={submitHandler}>
          <FormInput
            label="date"
            required={true}
            defaultValue={
              ledger.date && format(ledger.date.toDate(), "MM/dd/yyyy")
            }
            placeholder="mm/dd/yyyy"
          />
          <FormInput
            label="description"
            defaultValue={ledger.description}
            required={true}
          />
          <FormSelect
            label="vehicle"
            required={true}
            options={vehicles.map(
              (v) => `${v.make} ${v.model} ${v.year} ${v.color}`
            )}
            values={vehicles.map((v) => v.vin)}
          />
          <div className="grid">
            <label className="label">NOTES:</label>
            <textarea
              name="notes"
              className="input"
              defaultValue={ledger.notes}
            />
          </div>
          <FormInput
            label="amount"
            required={true}
            type="number"
            defaultValue={ledger.amount}
          />
          {ledger.attachments && (
            <div className="grid gap-2">
              <label className="label">ATTACHMENTS:</label>
              {ledger.attachments.map((url, idx) => (
                <a
                  key={idx}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm underline text-secondary"
                >
                  Attachment {idx + 1}
                </a>
              ))}
            </div>
          )}

          {Array(numFiles)
            .fill(null)
            .map((a, idx) => (
              <div className="flex items-center justify-between space-x-4">
                <FormInput label={`attachment ${idx + 1}`} type="file" />
                <FaTrash
                  onClick={() => setNumFiles(numFiles - 1)}
                  className="text-xl text-red-700 cursor-pointer"
                />
              </div>
            ))}
          <div className="flex items-center justify-between mt-8">
            <div className="flex space-x-4 text-sm underline cursor-pointer text-primary">
              <p onClick={() => setNumFiles(numFiles + 1)}>Add Attachment</p>
            </div>
            <div className="flex space-x-4">
              {id && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={deleteLedger}
                >
                  Delete
                </Button>
              )}
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </div>
        </form>

        {uploading && (
          <p className="text-sm text-stone-400">Uploading... {uploading}%</p>
        )}
        {warning && <p className="text-sm text-red-700">{warning}</p>}
      </div>
    </div>
  );
}
